import React from "react";
import "../Styles/Sponsors.css";
import "../Styles/Sponsors-responsive.css";
import Logo from "../Media/LOGO.png";
import RTS from "../Media/RTS.png";

export default function Sponsors() {
  return (
    <section className="Sponsors-section" id="Partner">
      <div className="container d-flex Sponsors-content">
        <h2>Our Partner</h2>
        <div className="Sponsors-img d-flex">
          <div className="d-flex">
            <img src={Logo} alt="LOGO" width="400px" />
          </div>
          <div className="d-flex">
            <h1 style={{ fontFamily: "sans-serif" }}>X</h1>
          </div>
          <div className="d-flex">
            <img src={RTS} alt="RTS" width="400px" />
          </div>
        </div>
        <div className="Sponsors-details">
          <h5>
            We've partnered with RTS Financial to offer you a comprehensive
            solution to your financial needs. With RTS Financial's factoring
            services, you can swiftly access funds for your invoices, covering
            vital expenses like fuel and maintenance. Plus, take advantage of
            fuel card discounts at major truck stops nationwide.
          </h5>
        </div>
      </div>
    </section>
  );
}
