import React from "react";
import "../Styles/Vehicles.css";
import "../Styles/Vehicles-responsive.css";
import PowerOnly from "../Media/PowerOnly.jpg";
import DryVan from "../Media/Dry-van.jpg";
import Reefer from "../Media/Reefer.jpg";
import FlatBed from "../Media/Flatbed.jpg";
import StepDeck from "../Media/Step-Deck.jpg";
import HotShot from "../Media/hotshot.jpg"
import BoxTruck from "../Media/BoxTruck.jpg"
export default function Vehicles() {
  return (
    <section className="Vehicles-section" id="Vehicles">
      <div className="Vehicles container">
        <h1 className="Vehicles-heading">Vehicles</h1>
        <div className="Vehicles-Content">
          <div className="d-flex card-vehicles-row-1">
            <div className="card-Vehicles">
              <img src={PowerOnly} alt="Description"/>
              <button className="Btn">Power Van</button>
            </div>
            <div className="card-Vehicles">
              <img src={DryVan} alt="Description"/>
              <button className="Btn">Dry Van</button>

            </div>
            <div className="card-Vehicles">
              <img src={Reefer} alt="Description"/>
              <button className="Btn">Reefer Van</button>

            </div>
          </div>
          <div className="d-flex card-vehicles-row-2">
            <div className="card-Vehicles">
              <img src={FlatBed} alt="Description"/>
              <button className="Btn">FlatBed</button>
            </div>
            <div className="card-Vehicles">
              <img src={StepDeck} alt="Description"/>
              <button className="Btn">StepDeck</button>
            </div>
            <div className="card-Vehicles">
              <img src={HotShot} alt="Description"/>
              <button className="Btn">Hotshot</button>
            </div>
          </div>
          <div className="d-flex card-vehicles-row-3">
            <div className="card-Vehicles">
              <img src={BoxTruck} alt="Description"/>
              <button className="Btn">Boxtruck</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
