import React from "react";
import "../Styles/Footer.css";
import "../Styles/Footer-responsive.css";
import { IconMail } from "@tabler/icons-react";
import { IconPhone } from "@tabler/icons-react";
import { IconMap } from "@tabler/icons-react";

export default function Contact() {
  return (
    <footer className="Footer" id="Contact">
      <div className="container">
        <div className="footer-content d-flex">
          <h2>Contact Details</h2>
          <div className="d-flex footer-content-box">
            <div className="d-flex email">
              <IconMail size={36} />
              <div>
                <h2>E-Mail</h2>
                <h4>shorditchenterprisesllc@gmail.com</h4>
              </div>
            </div>
            <div className="d-flex">
            <IconMap size={36} />
              <div>
                <h2>Address</h2>
                <h4>Address: 10601 Frisco Texas, 75033</h4>
              </div>
            </div>
            <div className="d-flex">
              <IconPhone size={36} />
              <div>
                <h2>Contact Number</h2>
                <h4>(469) 850-1252</h4>
              </div>
            </div>
          </div>
          <hr />
          <div className="footer-bottom d-flex">
            <p>Copyright © 2023. All rights are reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
