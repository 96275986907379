import React, { Component } from "react";
import "../Styles/Navbar.css";
import "../Styles/Navbar-responsive.css";
import Logo from "../Media/LOGO.png";
import { IconPhone } from "@tabler/icons-react";

export default class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      isSignInVisible: false, // Start with the menu hidden
      NavHeight: "40px",
      isSmallScreen: window.innerWidth <= 1200,
    };
  }

  toggleSignInVisibility = () => {
    this.setState((prevState) => ({
      isSignInVisible: !prevState.isSignInVisible,
      NavHeight: prevState.isSignInVisible ? "40px" : "40vh",
    }));
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const isSmallScreen = window.innerWidth <= 1200;
    this.setState({ isSmallScreen });
    if (isSmallScreen) {
      this.setState({
        isSignInVisible: false, // Hide the menu on smaller screens
        NavHeight: "40px",
      });
    }
  };

  render() {
    const isSmallScreen = this.state.isSmallScreen;
    const isSignInVisible = this.state.isSignInVisible;

    return (
      <section className="Navbar-Section">
        <div
          className="Navbar container d-flex justify-content-between"
          style={{ height: this.state.NavHeight }}
        >
          <div>
            <img
              className="Logo"
              alt="Logo"
              src={Logo}
              style={{ margin: "auto" }}
            />
          </div>
          <ul
            className="icons-navbar d-flex"
            style={{
              visibility:
                !isSmallScreen || isSignInVisible ? "visible" : "hidden",
            }}
            id="icon-navbar"
          >
            <li className="links-nav links-nav-home">
              <a href="#Home">Home</a>
            </li>
            <li className="links-nav links-nav-Services">
              <a href="#Services">Services</a>
            </li>
            <li className="links-nav links-nav-Vehicles">
              <a href="#Vehicles">Vehicles</a>
            </li>
            <li className="links-nav links-nav-About">
              <a href="#Partner">Our Partner</a>
            </li>
            <li className="links-nav links-nav-Contact">
              <a href="#Contact">Contact us</a>
            </li>
          </ul>
          <div className="Nav-Phone-logo">
            <a href="#Contact"><IconPhone size={30} />(469) 294-8292</a>
          </div>
          <div></div>
          <div className="hamburger" onClick={this.toggleSignInVisibility}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </section>
    );
  }
}
