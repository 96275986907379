import React from 'react'
import Navbar from '../Components/Navbar.js'
import Intro from '../Components/Intro.js'
import Services from '../Components/Services.js'
import Vehicles from '../Components/Vehicles.js'
import Footer from "../Components/Footer.js"
import Sponsors from '../Components/Sponsors.js'
export default function Home() {
  return (<>
    <Navbar/>
    <Intro/>
    <Services/>
    <Vehicles/>
    <Sponsors/>
    <Footer/>
    </>
  )
}
