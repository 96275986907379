import React, { useEffect } from "react";
import "../Styles/Services.css";
import "../Styles/Services-responsive.css";
import NoContract from "../Media/NoContract.jpg";
import DRU from "../Media/DRU.jpg";
import invoicing from "../Media/Invoicing.jpg";
import Paperwork from "../Media/Paperwork.jpg";
import Support from "../Media/Support.jpg";
export default function Services() {
  useEffect(() => {
    function scrollTrigger(selector, options = {}) {
      const els = document.querySelectorAll(selector);
      els.forEach((el) => {
        addObserver(el, options);
      });
    }

    function addObserver(el, options) {
      if (!("IntersectionObserver" in window)) {
        if (options.cb) {
          options.cb(el);
        } else {
          el.classList.add("active");
        }
        return;
      }

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (options.cb) {
              options.cb(entry.target); // Pass the entry.target to the callback
            } else {
              entry.target.classList.add("active");
            }
            observer.unobserve(entry.target);
          }
        });
      }, options);

      observer.observe(el);
    }

    scrollTrigger(".Card-content", {
      rootMargin: "-200px",
    });
  }, []);

  return (
    <section className="Services-section" id="Services">
      <div className="Services-content container d-flex">
        <div className="Services-Heading">
          <h2>Our Services</h2>
          <p>
            We take pride in the provision of the best truck dispatching
            services that are customized to meet the needs of carriers like you.
            We start with detailed planning, which is aimed at ensuring that
            your vehicle never stays idle and that your wheels keep turning
            during the entire work week. We fight for the highest freight rates
            for your loads, consequently increasing your net income and
            developing long-term partnerships. We eliminate all the paperwork
            hassles of invoice creation for factoring, enabling you to drive
            without any anxiety. Each carrier is guaranteed personalized service
            by a specialized dispatcher who studies your off days, personal
            preferences and work constraints, thus making sure that you will
            have consistent support while you are on the road. We go the extra
            mile by handling lumper, detention, layover, and the TONU costs as
            well as 24/7 dispatch and unlimited loads throughout the week. We
            offer a non-binding setup carrier package that you can use anytime,
            with no initial investment or mandatory dispatch. We put your
            happiness and your predilections first, and our goal is to reach the
            level of cooperation that is seamless and satisfying.
          </p>
        </div>
        <div className="Intro-hrline"></div>
        <div className="Card-content d-flex">
          <div className="d-flex card-content-row-1">
            <div className="card">
              <img src={Support} alt="Support"/>
              <h4>
                <b>24/7 Helpline</b>
              </h4>
            </div>
            <div className="card">
              <img src={NoContract} alt="Yup" />
              <h5>
                <b>No Contract</b>
              </h5>
            </div>
            <div className="card">
              <img src={invoicing} alt="Real" />
              <h5>
                <b>Factoring & Invoicing</b>
              </h5>
            </div>
          </div>
          <div className="d-flex card-content-row-2">
            <div className="card">
              <img src={Paperwork} alt="Paperwork"/>
                <h5>
                  <b>Paperwork & Collection</b>
                </h5>
            </div>
            <div className="card">
              <img src={DRU} alt="Yup" />
              <h4>
                <b>Driver Response Unit</b>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
