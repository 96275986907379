import React from "react";
import "../Styles/Intro.css";
import "../Styles/intro-responsive.css";
import BGHome from "../Media/BGHome.jpg"
export default function Intro() {
  return (
    <section className="Home" id="Home">
      <div className="Home-content">
        <div className="Home-main d-flex">
          <img src={BGHome} alt="Description"/>
          <div className="Home-content-text">
            <h1 style={{ fontSize: "4.5rem" }}>SHORDITCH DISPATCH</h1>
            <p style={{ fontSize: "1.5rem" }}>We Make Things Easier
            </p>
          </div>
          <div></div>
        </div>
      </div>
    </section>
  );
}
